<template>
  <div id="app">
 
    <main>
      <vue-page-transition name="fade-in-down">  
      <router-view/>
      </vue-page-transition>
    </main>
    <Footer/>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700;800&display=swap');

*,*::before,*::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  padding:0;
  margin:0;
}

html {
  scroll-behavior: smooth;
}

:root {

  --button-padding: 0.875rem 1.575rem;
  --container-padding: 2.575rem;
  --container-padding-sm: 0.938rem;
  --container-padding-xs: 0.313rem;
  --color-primary: rgba(80, 80, 204, 1);
  --color-secondary: rgba(28,84,175,1);
    --color-tertiary: rgba(99,197,219,1);
    --color-fourth:rgba(124,98,232,1);
  --color-buttons: rgba(120,211,244,1);
  --text-color-primary:rgba(24, 24, 33, 1);
  --text-color-secondary: rgba(80, 80, 204, 1);
  --linear-gradient: linear-gradient(90deg, rgb(28, 84, 175) 0%, rgba(124,98,232,1) 100%);
  --linear-gradient-reverse: linear-gradient(-90deg, rgba(28,84,175,1) 0%, rgba(124,98,232,1) 100%);
   --linear-gradient-top: linear-gradient(0deg, rgba(28,84,175,1) 0%, rgba(124,98,232,1) 100%);
   --linear-gradient-top-reverse: linear-gradient(180deg, rgba(28,84,175,1) 0%, rgba(124,98,232,1) 100%);
  --overlay-bg: var( --color-primary) !important;
}


#app {

  font-family: 'Manrope', sans-serif;
}

.fs-section-header {
  color:var(--text-color-secondary);
  margin:2rem 0;
}

.fs-section-header::after {
  position:relative;
  content:"\203A";
  font-size:2.225rem;
  margin-left:0.525rem;
  top:0.125rem;
}

.fs-button {
 display:inline-block;
  text-decoration:none;
  border:none;
  outline:none;
  padding: var(--button-padding);
  background: white;
  border-radius:15px;
  position:relative;
  color:white;
  font-weight:800;
  cursor: pointer;
  transition:0.5s all;
  z-index: 1;
}


.fs-button::before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: var(--color-primary);
  z-index:-1;
  border-radius:15px;
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
	transform-origin: 100% 50%;
}

.fs-button:hover {
  color: var(--color-primary);
}

.fs-button:hover::before {
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
}


.underline {
  text-decoration: underline;
  text-decoration-color: var(--color-buttons);
  text-decoration-thickness: 3px;
}

</style>

<script>

import 'normalize.css'
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>
