<template>
    <div class="fs-navbar">
    <div class="fs-brand">
        <img src="@/assets/brand.png" alt="">
    </div>
    <button id="menuToggler" class="fs-menu-toggler" v-bind:class="{ toggled: isToggled }" @click="isToggled = !isToggled">
            <svg width="40" height="40" viewBox="0 0 100 100" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M84 34H16V26H84V34Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M84 54H16V46H84V54Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M84 74H16V66H84V74Z"/>
            </svg>
        </button>
    <div class="fs-page-navigation" v-bind:class="{ toggled: isToggled }">
      
        <nav class="fs-page-navlinks">
            <router-link to="/" class="link">Αρχική</router-link>           
            <router-link to="/apps/portfolio/" class="link">Ενδεικτικές λύσεις</router-link>
            <router-link to="/services" class="link">Υπηρεσίες</router-link>
            <router-link to="/contact" class="link">Επικοινωνία</router-link>
        </nav>
    </div>
    </div>
</template>

<script>



    export default {
        name: 'NavigationBar',
        data: function() {
        return {
            isToggled: false
        };
}
    }
</script>

<style scoped>
.fs-navbar {
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    padding: var(--container-padding-sm) var(--container-padding);
    min-height: 2rem;
}

.fs-brand {
    margin-right: auto;
}

.fs-brand img {
    max-width:260px;
    width:auto;
}

.fs-page-navigation {
    margin-left: auto;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
}

.fs-page-navlinks a {
    position:relative;
    margin: 0 5px;
    padding:10px;
    text-decoration:none;
    overflow:hidden;
    color:var(--text-color-secondary);
   
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.link::before {
    content: '';
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
    /* show by default */
}

.link:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}


.fs-menu-toggler {
    display:none;
    border:none;
    border-radius:none;
    outline:none;
    background:white;
}

.fs-menu-toggler svg {
    color:var(--text-color-primary);
}


@media screen and (max-width:768px) {

    .fs-navbar {
        padding:var(--container-padding-sm);
       
    }
    
    .fs-menu-toggler {
    display:flex;
    }

    .fs-page-navlinks {
        display: flex;
        flex-direction:column;
        margin:10px 0;
    }

    .fs-page-navlinks a {
        margin:10px 0;
    }
  
    .fs-page-navigation {
        width:100%;
        height:0;
        opacity:0;
        overflow:hidden;
        transition: 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }

    .fs-page-navigation.toggled {
        opacity:1;
        height:15rem;
    }

}

</style>