<template>
  <div class="fs-home-content">
      <NavigationBar/>
    <div class="fs-hero">
       
        <div class="fs-hero-left">
          <h2>ΦΥΔΑΝΤΣΗΣ ΝΙΚΟΛΑΟΣ</h2>
          <h1>Λύσεις μηχανογράφησης <span class="underline">τελευταίας τεχνολογίας</span> και υψηλής τεχνογνωσίας προσαρμόσιμες <span class="underline">σε κάθε περιβάλλον</span> ανάλογα με τις ανάγκες και δυνατότητες σας </h1>
        </div>
        <div class="fs-hero-right">
        </div>
    </div>
   
   <section class="about-us">
    <div class="about-us_header">
      <h2 class="fs-section-header">ΣΧΕΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</h2>
      <div class="about-us_numbers">
        <div class="about-us_number">
           <span class="number">20+</span>
           <span class="label">χρόνια εμπειρίας</span>
        </div>
        <div class="about-us_number">
          <span class="number">20+</span>
          <span class="label">κάθετες custom λύσεις</span>
        </div>
      </div>
    </div>
    <div class="about-us_content">
      
      <div class="about-us_info">
        <div class="about-us_info-image">
          <img src="@/assets/bioImagenew.jpg" alt="" srcset="" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
        </div>
        <div class="about-us_info-text">
           <p>
            Με 20 και πλέον έτη εμπειρίας στον τομέα της κάλυψης μηχανογραφικών αναγκών και του προγραμματισμού, έχουμε τη δυνατότητα πλήρους ανάλυσης των αναγκών του εκάστοτε πελάτη και την ανάπτυξη όχι μόνο ειδικών εφαρμογών-επεκτάσεων (custom) που καλύπτουν συγκεκριμένες ανάγκες, αλλά και κάθετων λύσεων με σύγχρονα εργαλεία για οποιαδήποτε μορφή λύσης χρειαστεί, από εφαρμογές Windows μέχρι εφαρμογές για κινητές συσκευές (Android). <br> <br>
            Η παροχή υπηρεσιών μας προσφέρεται είτε με φυσική παρουσία στο χώρο του τελικού πελάτη, είτε μέσω τηλεφωνικής επικοινωνίας, είτε μέσω διαδικτύου. 
            </p>
            <div class="about-us_apps">
        <div class="about-us_appwidget">
          <div class="about-us_appwidget-icon">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.91 339.76">
                <path d="M339.71 159.2q-54.9 0-109.7-.1-32.85 0-65.6.1c-2.5 0-3.4-.6-3.4-3.3q.15-63 0-126c0-2.2.3-3.2 2.9-3.6 30.9-4.5 61.8-9.2 92.7-13.8 27.7-4.2 55.4-8.3 83.2-12.5q-.15 79.65-.1 159.2ZM336.41 339.6c-4.3-1.8-9-1.8-13.5-2.5-35.2-5.4-70.5-10.6-105.7-15.9-17.8-2.7-35.6-5.4-53.4-7.9-2.4-.3-2.8-1.2-2.8-3.4 0-40.7.1-81.3 0-122 0-3.1 1.3-3.3 3.7-3.3 56.9.1 113.8 0 170.8 0 1.4 0 2.9-.2 4.3-.2q0 75.9.1 151.9c-.1 2.8-.7 3.9-3.5 3.3ZM68.41 161c-21.6 0-43.1 0-64.7.1-2.7 0-3.8-.4-3.7-3.5q.15-52 0-104.1c0-2.6.7-3.4 3.3-3.8 32.8-4.7 65.5-9.6 98.3-14.5 10.9-1.6 21.8-3.2 32.7-4.9 2.2-.4 2.9 0 2.9 2.4q-.15 62.85 0 125.7c0 3-1.5 2.8-3.5 2.8-21.7-.3-43.5-.2-65.3-.2ZM68.71 184.7c21.7 0 43.3 0 65-.1 2.7 0 3.5.7 3.5 3.5-.1 39.5-.1 78.9 0 118.4 0 2.9-.6 3.4-3.5 2.9q-46.05-6.9-92-13.6c-13.1-1.9-26.2-4-39.3-5.8-1.9-.3-2.3-.9-2.3-2.7 0-33.4.1-66.8 0-100.1 0-2.6 1.3-2.5 3-2.5Z"/>
             </svg>
          </div>
          <div class="about-us_appwidget-label">
            <h3 class="underline">WINDOWS</h3>
            <p>Ανάπτυξη custom Windows εφαρμογών με χρήση τελευταίας τεχνολογίας .NET</p>
          </div>
        </div>
          <div class="about-us_appwidget">
          <div class="about-us_appwidget-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.13 338.65">
              <path d="M103.15 338.65c-6.7-1.9-12.6-5.1-15.5-11.7a29.73 29.73 0 0 1-2.5-11.8c-.3-14.3-.1-28.7-.1-43v-4.3h-13.5c-9.6-.1-14.7-5.2-14.8-14.8-.1-46.2 0-92.4 0-138.6 0-.5.1-1.1.1-1.9h169.3c.1 1.1.2 2.2.2 3.2 0 45.3.1 90.6-.1 135.9a23.43 23.43 0 0 1-2.5 10.4c-2 4-6.2 5.5-10.6 5.7-4.8.1-9.7 0-14.9 0-.1 1.5-.2 2.8-.2 4 0 13.5-.3 26.9.1 40.4.4 12.9-4.2 22.4-18.3 26.4h-6c-14-3.9-18.8-13.5-18.4-26.3.4-13.5.1-27.1.1-40.6 0-1.2-.1-2.3-.2-3.5h-28.1v4.2c0 14.2.2 28.4-.1 42.6a33.67 33.67 0 0 1-2.6 12.1c-2.9 6.6-8.8 9.8-15.6 11.6ZM226.45 98.05H56.65c.9-28.4 12.3-51 35.2-67.9-5.9-5.9-11.7-11.6-17.4-17.4-3.5-3.6-3.8-7.5-.9-10.5 3.1-3.2 7-3 10.8.7 5.4 5.3 10.9 10.6 16.1 16.2 2.1 2.3 3.9 2.5 6.7 1.3q34.35-14.1 68.7 0c2.8 1.2 4.6 1 6.7-1.3 5.3-5.6 10.8-11 16.3-16.4 2-2 4.4-3.4 7.4-2.3a7.16 7.16 0 0 1 5 5.6c.5 2.9-1 5-2.9 7l-17.2 17.2c23 16.8 34.3 39.4 35.3 67.8Zm-120.3-24.6a10.44 10.44 0 0 0 10.5-10.5 10.55 10.55 0 1 0-10.5 10.5Zm70.8 0a10.55 10.55 0 1 0-10.5-10.4 10.5 10.5 0 0 0 10.5 10.4ZM42.45 182.85c0 16.2.1 32.4 0 48.6-.1 11.2-7.6 20.4-17.7 21.9a21.23 21.23 0 0 1-24.6-21.3c-.2-32.6-.2-65.3 0-97.9a21.64 21.64 0 0 1 19.2-21.7c10-1.1 19.9 6.1 22.5 16.5a31.65 31.65 0 0 1 .6 6.9ZM240.65 182.85v-47.9c0-10 5.5-18 14-21.1 14.2-5.1 28.3 5.5 28.4 21.4q.15 34 0 68.1c0 10.7.3 21.4-.5 32s-11.2 18.9-21.2 18.4c-10.9-.6-20.3-9.2-20.5-20-.3-17-.1-33.9-.1-50.9Z"/>
            </svg>
          </div>
          <div class="about-us_appwidget-label">
            <h3 class="underline">ANDROID</h3>
            <p>Ανάπτυξη εφαρμογών για κινητά και tablets Android με δυνατότητα χρήσης και εκτός δικτύου</p>
          </div>
        </div> 
        <div class="about-us_appwidget">
          <div class="about-us_appwidget-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.45 318.71">
              <path d="M318.45 149.51v19.3c-.8 6.5-1.7 13.1-2.5 19.9-7.5-2.5-14.5-5-21.6-7.1-3.3-1-4.7-2.4-3.4-5.8a16.19 16.19 0 0 0 .2-3.3c-6.5 0-12.8.2-19.1-.1a31.52 31.52 0 0 1-9.1-1.5c-25.7-8.4-51.3-17-76.2-25.3h27.5c-1.6-13.4-3.1-26.5-4.6-39.4-12.9 1.2-24.9 2.4-36.9 3.5v36.1h6.8c-17.5 2.9-28.5 12.8-33.3 30.6-.2-1.8-.3-2.8-.4-3.9h-41.5c1.5 13.5 3 26.8 4.6 40.4 1.7-.2 2.8-.4 3.8-.5 8.5-.9 17.1-2 25.6-2.7 7.7-.6 7.8-.3 7.8-8.1v-12a39.77 39.77 0 0 1 2.3 5.9c6.8 20.5 13.5 41.1 20.6 61.5 2.5 7.3 4.1 14.5 3.5 22.1-.2 2.5 0 5 0 8.1 2.1-1.5 3.6-2.6 5.2-3.8 3.6 10.9 7.2 21.5 10.7 32.2a7 7 0 0 1-1.4.3c-6.1.8-12.3 1.6-18.4 2.5h-19.3l-3-.6c-5.6-.8-11.3-1.2-16.9-2.3-39.9-7.9-72.4-27.9-96.9-60.3-28.3-37.4-38.2-79.7-29.5-125.8 8.1-43.1 30.9-77.1 67-101.8 34.9-23.9 73.7-32 115.4-25.4 29.8 4.7 56 17.3 78.6 37.3 28 24.7 45.5 55.5 52 92.3 1 6 1.6 11.8 2.4 17.7Zm-270.9 81.2c12.1-4.3 23.9-8.5 35.2-12.5-1.7-15.2-3.4-30.3-5-45.4h-50.4a131.26 131.26 0 0 0 20.2 57.9Zm35.2-130c-11.4-4.1-23.1-8.2-35.2-12.5a133.45 133.45 0 0 0-20.2 57.5h50.3c1.7-15.2 3.4-30.1 5.1-45Zm188.3-12.5c-12.2 4.3-23.9 8.5-35.2 12.5 1.7 14.9 3.3 29.9 5 44.9h50.4a133 133 0 0 0-20.2-57.4Zm-166.7 57.4h41.5v-35.9c-12.2-1.2-24.2-2.3-37-3.6-1.5 13.1-3 26.2-4.5 39.5Zm41.5-114.3c-16.2 12.9-23.5 30.3-30 49 10.3 1 20 1.9 30 2.8Zm56.3 49.1c-2.9-16.8-20.7-45.6-29.5-48.1v50.8c10-.9 19.7-1.8 29.5-2.7Zm-85.8 158.1c2.7 16.5 20.5 45.4 29.4 48.1v-50.8c-9.8.8-19.5 1.7-29.4 2.7Zm98-199.6c5 11.8 10 23.5 15.2 35.8l24.3-8.2a132.62 132.62 0 0 0-39.5-27.6Zm-125.4 35.8c5.3-12.5 10.3-24.2 15.3-35.8a131.36 131.36 0 0 0-39.5 27.8c8.6 2.8 16.6 5.5 24.2 8Zm-24.3 177.5a131.59 131.59 0 0 0 39.6 27.7c-5-11.7-10-23.5-15.2-35.8-7.6 2.6-15.6 5.2-24.4 8.1Z"/>
              <path d="M222.05 318.61c-4.2-1.7-6.5-4.9-7.9-9.2q-20.25-61.2-40.7-122.4c-2.4-7.1.9-13.1 7.6-14.1a12.41 12.41 0 0 1 5.4.7q62.1 20.55 124.2 41.3c3.9 1.3 6.2 3.9 7.8 7.4v5c-1.8 4.2-5.1 6.4-9.3 7.9q-25.5 9.6-50.9 19.6a7 7 0 0 0-3.6 3.6q-9.9 25.2-19.5 50.7c-1.6 4.2-3.7 7.8-8 9.6-1.8-.1-3.4-.1-5.1-.1Z"/>
            </svg>
          </div>
          <div class="about-us_appwidget-label">
            <h3 class="underline">ΔΙΚΤΥΑΚΕΣ ΕΦΑΡΜΟΓΕΣ</h3>
            <p>Ανάπτυξη και υποστήριξη δικτυακών εφαρμογών (τοπικού δικτύου ή online) και ιστοσελίδων/web shops</p>
          </div>
        </div>
         
      </div>
        </div>
      </div>
    </div>
   </section>
    
    <div class="fs-sample-portfolio">
        <h2 class="fs-section-header">ΚΑΠΟΙΕΣ ΑΠΟ ΤΙΣ ΛΥΣΕΙΣ ΜΑΣ</h2>
        <div class="fs-portfolio-samples">
          <div  class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              <h3 class="underline">Agripharm</h3>
              <span>Εφαρμογή φυτοπροστασίας και συνταγογράφησης γεωργικών φαρμάκων</span>
              
            </div>
             <img src="@/assets/agripharm1.jpg">
          </div>
          <div class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              <h3 class="underline">Agrigarden</h3>
              <span>Διαχείριση θερμοκηπίων για παραγωγή σποροφύτων <br> Έλεγχος αποθήκης σπόρων</span>
            </div>
            <img src="@/assets/agrigarden1.jpg">
          </div>
          <div class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              <h3 class="underline">GLXShop</h3>
              <span>Headless CMS λύση για ηλεκτρονικά καταστήματα που συνδέεται με το SL Galaxy</span>
            </div>
             <img src="@/assets/vglshop1.jpg">
          </div>
          <div class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              
              <h3 class="underline">Ωροδείκτης</h3>
              <span>Διαχείριση ωρομέτρησης με δυνατότητα εξαγωγής δεδομένων για μισθοδοσία</span>
            </div>
            <img src="@/assets/workagent1.jpg">
          </div>
          
          <div class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              <h3 class="underline">MobiDocs</h3>
              <span>Φορητή τιμολόγηση από συσκευές Android (custom for SL Galaxy) </span>
            </div>
            <img src="@/assets/mobidocs1.jpg">
          </div>
          <div class="fs-portfolio-sample">
            <div class="fs-portfolio-sample-description">
              <h3 class="underline">Web Connector</h3>
              <span>Διασύνδεση του e-shop σας με το SL Galaxy (σύνδεση αρχείων)</span>
            </div>
            <img src="@/assets/webconnector1.jpg">
          </div>
        </div>
    </div>
    <div class="fs-customers">
        <h2 class="fs-section-header">ΣΥΝΕΡΓΑΣΙΕΣ ΜΕ...</h2>
        <div class="fs-customers-logos">
          <div class="fs-customers-logo exfrut" data-aos="zoom-in" data-aos-duration="2000">            
          </div>
          <div class="fs-customers-logo almi" data-aos="zoom-in" data-aos-duration="2000">
          </div>
          <div class="fs-customers-logo soulis" data-aos="zoom-in" data-aos-duration="2000">     
          </div>
          <div class="fs-customers-logo dedes" data-aos="zoom-in" data-aos-duration="2000">
          </div>
          <div class="fs-customers-logo multitaste" data-aos="zoom-in" data-aos-duration="2000">           
          </div>
          <div class="fs-customers-logo toptsis" data-aos="zoom-in" data-aos-duration="2000">
          </div>
         
        </div>
    </div>
    <div class="fs-home-contact">

         <h2 class="fs-section-header">ΕΠΙΚΟΙΝΩΝΙΑ</h2>
       
         <div class="fs-home-contact-wrapper">
           <div class="fs-contact-details">
             <div class="address-details">
               <div class="address-details-icon">
                  <svg id="dc489878-cfe7-4951-b2bd-f6e2547b9350" fill="url(#svg-gradient)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>address</title><path d="M428,973q34.78-30,69.55-60.1Q589.67,833,681.73,753c.62-.54,1.29-1,2.36-1.84q9,7.82,18,15.61Q820.25,869.32,938.38,971.9A14.88,14.88,0,0,0,940,973v1l-22.56,25.26-3.72,4.15-23.83-20.63v6.83q0,93.73,0,187.46c0,27.41-15.45,49.92-40.16,58.51-4.68,1.63-9.69,3.08-14.57,3.16-23.66.37-47.32.24-71,.29h-5.47a12.49,12.49,0,0,1,1.06-2c10.36-12,20.69-23.94,31.2-35.76a7.63,7.63,0,0,1,5.06-2.12c10.83-.2,21.66,0,32.49-.12,13-.1,21.37-8.28,21.39-21.24q.12-113.24-.13-226.45c0-2.29-2-5.12-3.93-6.77Q783.7,890.37,721.35,836.37L684.06,804c-2.85,2.42-5.63,4.75-8.36,7.12q-76.62,66.51-153.29,133a11.51,11.51,0,0,0-4.44,9.75q.21,111.23.11,222.46c0,11.9,5.86,20.26,16,22.26,3.55.7,7.3.45,11,.47,9,.06,18,0,27,.08,1.51,0,3.56.37,4.43,1.38,10.89,12.55,21.6,25.25,32.64,38.25-1.61.09-3.16.26-4.72.26q-32,0-64,0c-36.36-.07-62.2-26-62.23-62.46q-.08-93.48,0-187v-6.76l-23.76,20.44L428,974Z" transform="translate(-428 -751.12)"/><path d="M681,963.25c57.89.44,104.74,38.71,115.58,92.25,5.83,28.78,1.33,56.23-12.2,82.29-18.32,35.27-44.64,64.07-73.33,90.92-7.65,7.16-15.93,13.66-23.84,20.55-2,1.75-3.5,2.38-5.94.38-34.87-28.54-66.39-60.14-90.29-98.7-18.69-30.18-27.47-62.64-18.8-97.78,11.6-47,41.74-76.07,88.8-87.3C668.49,964.06,676.42,963.81,681,963.25Zm3,236c20.59-17.92,44.44-45.59,58.5-68.19,10.83-17.42,17.79-35.89,16.43-57-2.55-39.62-36-71.56-75.32-70.76-53.42,1.09-86.57,52.12-70.59,101.14,5.59,17.16,15.37,32,26.18,46.29C652.49,1168.27,667.73,1184,684,1199.28Z" transform="translate(-428 -751.12)"/><path d="M684.22,1108a30.11,30.11,0,0,1-30.17-29.67c-.16-16.27,13.34-30,29.73-30.19,16.19-.21,30,13.41,30.16,29.74A30.09,30.09,0,0,1,684.22,1108Z" transform="translate(-428 -751.12)"/>
                  <defs>
                    <linearGradient id="svg-gradient" x2="1" y2="1">
                      <stop offset="0%" stop-color="var(--color-primary)" />
                      <stop offset="100%" stop-color="var(--color-fourth)" />
                    </linearGradient>
                  </defs>
                  </svg>
               </div>
               <address class="underline">
                 Πάροδος Μιχ. Λογίου 4<br>
                 Νάουσα, Ημαθία / 59200<br>
                 2332 0 52260
               </address>
             </div>
             <div class="fs-contact-map"></div>
           </div>
           <div class="fs-contact-form">
            <ContactForm/>
           </div>
         </div>
    </div>
    
  </div>
</template>

<style scoped>
  .fs-hero {
    width:100%;
    min-height:25rem;
    display: flex; 
    position: relative;
  }

  .fs-hero .fs-navbar {
    position: absolute;
    top:0;
    left:0;
    right:0;

  }
  
     .fs-hero-left {
        width:45%;
        min-height:25rem;
        height:auto;
        background-image:url("../assets/bgleft.jpg");
        background-size:cover;
        display:flex;
        flex-direction:column;
        justify-content: center;
        padding:0 55px;
    }

  .fs-hero-left h2 {

    font-size:1rem;
    font-weight:800;
    color:var(--text-color-secondary);
  }

  .fs-hero-left h1 {
    margin-top:.755rem;
    font-size:1.255rem;
    font-weight:400;
    color:var(--text-color-primary);
  }


  .fs-hero-right {
     width:55%;
    justify-content:flex-end;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
  }
  
  .fs-hero-badge {
    width:130px;
    height:auto;
    margin:3rem 0;
  }




  .about-us {
    padding:var(--container-padding);
  }

  .about-us_header {
    min-height:8rem;
    width:100%;
    display:flex;
    align-items:center;
    justify-content: space-between;
    border-bottom:1px solid #dcdcdc;
  }

  .about-us_numbers {
    display:flex;
    gap:2rem;
  }

  .about-us_number {
    min-width:10rem;
  }
  
  .about-us_number .number {
    display:block;
    font-size:2.5rem;
    font-weight:800;
    color:var(--color-primary);
  }

 .about-us_content {
  min-height:30rem;
  padding:4rem 0;
 }

 .about-us_apps {
  display:flex;
  margin-top:3rem;
  gap:2rem;
 }

 .about-us_appwidget {
  display:flex;
  width:33.33%;
  justify-content: center;
  gap:1rem;
 }

 .about-us_appwidget-icon svg {
      width:4rem;
        height:auto;
        fill: var(--color-primary) !important;
 }

.about-us_info {
  display:flex;
  gap:2rem;
}

.about-us_info-image {
  width:280px;
  flex:none;
}

.about-us_info-image img {
  max-width:100%;
  height:auto;
}

  .fs-sample-portfolio {
    min-height:40rem;
    padding:var(--container-padding);
  }

  .fs-portfolio-samples {
     display:flex;
    flex-wrap: wrap;
    gap:2rem;
    
  }

 

   .fs-portfolio-sample {
     min-height:3rem;
     aspect-ratio:1;
     flex-basis: calc(33.33% - 2rem);
     display:flex;
     padding:.75rem;
     overflow:hidden;
     position: relative;
     background:white;
     z-index:1;
   }

   .fs-portfolio-sample img {
   position: absolute;
   max-width:100%;
   aspect-ratio:1;
   object-fit: fill;
   inset:0;
   }

   .fs-portfolio-sample-description {
      
     align-self: flex-end;    
     width:100%;    
      color:white;
     padding:1rem;
     height:7rem;
     display:flex;
     flex-direction: column;
     justify-content: space-between;
     overflow: hidden;
     position: relative;
     background-color:transparent;
     z-index:2;
   }

  .fs-portfolio-sample-description::before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
background:var(--color-primary);
mix-blend-mode: hard-light;
opacity:.75;
   z-index:-1;
  }

.fs-portfolio-sample-description h3 {
  font-size:1rem;
  font-weight: bold;
}

.fs-portfolio-sample-description span {
  font-size:0.85rem;
  font-weight:normal;
}


  .fs-customers {
    min-height:25rem;
    padding:var(--container-padding);
    padding-bottom:3rem;
  }

  .fs-customers-logos {
    display:flex;
    flex-wrap: wrap;
    gap:1rem;

  }

 .fs-customers-logo {
  flex-basis: calc(33.3333% - 1rem);
  transition: background 0.35s ease;
  min-height:12rem;

 }

.exfrut {
background:url(../assets/exfrut_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.exfrut:hover {
background:url(../assets/exfrut.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.almi {
background:url(../assets/almi_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.almi:hover {
background:url(../assets/almi.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.soulis {
background:url(../assets/soulis_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.soulis:hover {
background:url(../assets/soulis.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.dedes {
background:url(../assets/dedes_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.dedes:hover {
background:url(../assets/dedes.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.multitaste {
background:url(../assets/multitaste_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.multitaste:hover {
background:url(../assets/multitaste.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.toptsis {
background:url(../assets/toptsis_n.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}

.toptsis:hover {
background:url(../assets/toptsis.jpg);
background-size:100% auto;
background-repeat: no-repeat;
}


.fs-customers-logo img {
  max-width:100%;
  width:auto;
}

.fs-home-contact {
  min-height:45rem;
  padding:var(--container-padding);
  display:flex;
  flex-direction: column;
  padding-bottom:5rem;
  background:url(../assets/productBackground.jpg);
  background-size:cover;
  background-position: center center;
}

.fs-home-contact-wrapper {
  display:flex;
}

.fs-home-contact-wrapper div {
  flex:auto;
}

.fs-contact-details {
  width:40%;
  display: flex;
  flex-direction: column;
  gap:3rem;
}

.fs-contact-details address {
  font-style: normal;
  color:var(--text-color-primary);

}

.address-details {
  display: flex;
  max-height:8rem;
  padding:0 1rem;
  overflow: hidden;
  align-items: center;
  background:white;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-top:4px solid var(--color-tertiary);
    border-radius:30px;
}

.address-details-icon {
  flex: none !important;
    margin-right:2rem;
  width:5rem;
  aspect-ratio:1;
  padding:1.155rem;
  display:grid;
  place-items:center;
}

.fs-contact-map {
  width:100%;
  background:url(../assets/map_temp.jpg);
  background-size:cover;
}

.fs-contact-form {
  width:60%;
   color:var(--text-color-primary);
}



  @media screen and (max-width:992px) { 

    .fs-hero .fs-navbar {
      background:white;
      --text-color-primary:rgba(27, 47, 86, 1);
      --text-color-secondary: rgba(28,84,175,1);
    }

    .fs-hero-left {
      width:100%;
    }

    .fs-hero-right {
      display:none;
    }

    

    .fs-customers-logo {
      flex-basis: calc(50% - 1rem);
    }

    .fs-portfolio-sample {
     flex-basis: calc(50% - 1.575rem);
   }

    .fs-home-contact-wrapper {
      flex-direction: column;
      gap:3rem;
    }

    .fs-contact-details {
      width:100%;
    }

    .fs-contact-map {
      height: 30rem;
      width:100%;
    }

    .fs-contact-form {
      width:100%;
    }


  }

  @media screen and (max-width:768px) {
    .about-us_header { 
        flex-direction: column;
        align-items: flex-start;
    }

    .about-us_info {
      flex-direction: column;
    }

    .about-us_info-image {
      order:2;
      width:100%;
      flex:auto;
    }

    .about-us_apps {
      flex-direction: column;   
    }

    .about-us_appwidget {
      width:100%;
    }
  }

   @media screen and (max-width:425px) {


    .about-us_numbers {
      flex-direction: column;
    }


      .fs-customers-logo {
      flex-basis: calc(100% - 1rem);
    }
      .fs-portfolio-sample {
     flex-basis: calc(100% - 1.575rem);
   }

    }



</style>


<script>
// @ is an alias to /src

import NavigationBar from '@/components/NavigationBar.vue';
import ContactForm from '@/components/ContactForm.vue';


export default {
  name: 'Home',
  components: {
    NavigationBar,
    ContactForm
  }
}
</script>
