<template>
    <div class="fs-contact"> 
        <form action="">
            <div class="fs-input-group">
            <label for="senderName">Όνομα</label>
            <input type="text" id="senderName" v-model="senderName">
            </div>
            <div class="fs-input-group">
            <label for="senderSurname">Επώνυμο</label>
            <input type="text" id="senderSurname" v-model="senderSurname">
            </div>
            <div class="fs-input-group">
            <label for="senderEmail">Διεύθυνση email</label>
            <input type="text" id="senderEmail" v-model="senderEmail">
            </div>
            <div class="fs-input-group">
            <label for="messageTitle">Λόγος επικοινωνίας</label>
            <input type="text" id="messageTitle" v-model="messageTitle">
            </div>
            <div class="fs-input-group fs-span-2">
            <label for="messageContent">Κείμενο μηνύματος</label>   
            <textarea id="messageContent" v-model="messageContent"></textarea>
            </div>
            <button class="fs-button fs-contact-submit" id="formSubmit" @click.prevent="sendMail">Αποστολή</button>
        </form>
    </div>
</template>

<script>

    import axios from 'axios';

    export default {
        name: 'ContactForm',
        data: function() {
            return {
                senderName: '',
                senderSurname: '',
                senderEmail: '',
                messageTitle: '',
                messageContent: ''
            };
        },
        methods: {
            sendMail() {
                
                var senderFullName = this.senderSurname + ' ' + this.senderName;

                const messageData = {
                        SenderName: senderFullName,
                        SenderEmail: this.senderEmail,
                        MessageTitle: this.messageTitle,
                        MessageContent: this.messageContent
                };

            
                axios.post("http://mailservice.nfydantsis.gr/api/Message", messageData)
                   .then(this.clearMailData);

                   
               
            },

            clearMailData() {
                // Reset form field
                  this.senderSurname = '';
                  this.senderName = '';
                  this.senderEmail = '';
                  this.messageTitle = '';
                  this.messageContent = '';
            }
        }
    }
</script>

<style scoped>
  .fs-contact {
       padding:0 var(--container-padding);
  }

    .fs-contact-address {
        display: flex;
        padding:3rem 0;
        gap:3rem;
    }

  .fs-contact form {
     display:grid;
      grid-template-columns:repeat(2,minmax(0,1fr));
      gap:3em;
   }

    .fs-span-2 {
        grid-column: span 2;
    }

    .fs-input-group label {
        display:block;
    }

    .fs-input-group input, .fs-input-group textarea {
        width:100%;
        padding:10px;
        margin:10px 0;
        border:1px solid #bdc3c7;
        outline:none;
    }

    .fs-input-group textarea { 
        min-height:10rem;
        resize: none;
    }

    .fs-input-group input:focus, .fs-input-group textarea:focus {
        outline:none;
    }

    .fs-input-group input:focus-visible, .fs-input-group textarea:focus-visible {
       border-color:var(--color-secondary);
       outline:2px solid var(--color-secondary);
    }

    .fs-contact-submit {
        grid-column: span 2;
        place-self: end;
    }

     @media screen and (max-width:768px) {
        
 .fs-contact {
     --container-padding:0.125rem;
       padding:0 var(--container-padding);
  }

        .fs-input-group { 
          grid-column: span 2 !important;
        }

     }

</style>